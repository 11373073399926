import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './Component/Home/Home'
import Fp2 from './Component/Home/Fp2'
import Fp3 from './Component/Home/Fp3'
import './App.scss'
import Landing from './Page/Landing'
import Chatgpt from './Page/Chatgpt/Chatgpt'
import Chatgpt1 from './Page/Chatgpt/custom'
import videoVideoIdMainfile from './Component/VideoIdcapture'

// window.addEventListener("beforeunload", (ev) => 
// {  
//     ev.preventDefault();
//     return ev.returnValue = 'Are you sure you want to close?';
// });
export default class App extends Component {
  render() {
    return (
      <>
        <div>

          <>
            <Router>
              <Switch>
                <Route path="/" exact component={Chatgpt} />
                <Route path="/:id" exact component={Chatgpt} />
                {/* <Route path="/:id" exact component={Chatgpt} /> */}
                <Route path="/chaast" exact component={Chatgpt1} />
              </Switch>
            </Router>
          </>

        </div>
      </>
    )
  }
}


